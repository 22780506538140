import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getDigitalVolunteerIndividualDetails = createAsyncThunk(
    "digitalVolunteerIndividualDetails/getDigitalVolunteerIndividualDetails",
    async (slug, thunkAPI) => {
        const res = await fetch(`${baseURL}/digital-volunteer/${slug}`).then(
            (data) => data.json()
        );
        return res;
    }
);

export const digitalVolunteerIndividualDetailsSlice = createSlice({
    name: "digitalVolunteerIndividualDetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            getDigitalVolunteerIndividualDetails.pending,
            (state) => {
                state.loading = true;
            }
        );
        builder.addCase(
            getDigitalVolunteerIndividualDetails.fulfilled,
            (state, action) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(
            getDigitalVolunteerIndividualDetails.rejected,
            (state) => {
                state.loading = false;
            }
        );
    }
});

export const digitalVolunteerIndividualDetailsSelector = (state) =>
    state.digitalVolunteerIndividualDetails;

export default digitalVolunteerIndividualDetailsSlice.reducer;
