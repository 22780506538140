import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import TeamDetails from "../pages/AboutPage/TeamDetailsPage";
import PageNotFound from "../pages/OtherPages/PageNotFound";
import Loader from "../components/loader/Loader";
import MembersDetailPage from "../pages/DataDrivenAdvocacy/MembersDetailPage";
// import GrantWinnersDetails from "../pages/ClimateSmartEntrepreneurship/GrantWinnersDetails";

const HomePage = lazy(() =>
    import(/* webpackChunkName: "HomePage" */ "../pages/HomePage/HomePage")
);
const LandingPageCSE = lazy(() =>
    import(
        /* webpackChunkName: "LandingPageCSE" */ "../pages/ClimateSmartEntrepreneurship/LandingPageCSE"
    )
);
const IdeaSubmitPageCSE = lazy(() =>
    import(
        /* webpackChunkName: "IdeaSubmitPageCSE" */ "../pages/ClimateSmartEntrepreneurship/IdeaSubmitPageCSE"
    )
);
const IdeaRepository = lazy(() =>
    import(
        /* webpackChunkName: "IdeaRepository" */ "../pages/ClimateSmartEntrepreneurship/IdeaRepositoryPageCSE"
    )
);
const IdeaDetailPage = lazy(() =>
    import(
        /* webpackChunkName: "IdeaDetailPageCSE" */ "../pages/ClimateSmartEntrepreneurship/IdeaDetailPageCSE"
    )
);

const InnovationGrantWinners = lazy(() =>
    import("../pages/ClimateSmartEntrepreneurship/GrantWinners")
);

const InnovationGrantWinnersDetails = lazy(() =>
    import("../pages/ClimateSmartEntrepreneurship/GrantWinnersDetails")
);
const AboutUsPage = lazy(() =>
    import(
        /* webpackChunkName: "AboutUsPage" */ "../pages/AboutPage/AboutUsPage"
    )
);
const OurTeamPage = lazy(() =>
    import(
        /* webpackChunkName: "OurTeamPage" */ "../pages/AboutPage/OurTeamPage"
    )
);
const EventMainPage = lazy(() =>
    import(
        /* webpackChunkName: "EventMainPage" */ "../pages/EventPage/EventMainPage"
    )
);
const EventsPage = lazy(() =>
    import(/* webpackChunkName: "EventsPage" */ "../pages/EventPage/EventsPage")
);
const NewsPage = lazy(() =>
    import(/* webpackChunkName: "NewsPage" */ "../pages/NewsPage/NewsPage")
);
const NewsDetailsPage = lazy(() =>
    import(
        /* webpackChunkName: "NewsDetailsPage" */ "../pages/NewsPage/NewsDetailsPage"
    )
);
const AboutCSE = lazy(() =>
    import(
        /* webpackChunkName: "AboutCSE" */ "../pages/ClimateSmartEntrepreneurship/AboutCSE"
    )
);
const ClimateFellowshipPage = lazy(() =>
    import(
        /* webpackChunkName: "ClimateFellowshipPage" */ "../pages/ClimateFellowship/ClimateFellowshipPage"
    )
);
const AboutClimateFellowship = lazy(() =>
    import(
        /* webpackChunkName: "AboutClimateFellowship" */ "../pages/ClimateFellowship/AboutClimateFellowship"
    )
);
const LandingPageYSB = lazy(() =>
    import(
        /* webpackChunkName: "YouthSoundingBoardPage" */ "../pages/YouthSoundingBoard/LandingPageYSB"
    )
);
const AboutYouthSoundingBoard = lazy(() =>
    import(
        /* webpackChunkName: "AboutYouthSoundingBoard" */ "../pages/YouthSoundingBoard/AboutYouthSoundingBoard"
    )
);

const LandingPageDDA = lazy(() =>
    import(
        /* webpackChunkName: "YouthSoundingBoardPage" */ "../pages/DataDrivenAdvocacy/LandingPageDDA"
    )
);
const AboutDataDrivenAdvocacy = lazy(() =>
    import(
        /* webpackChunkName: "AboutYouthSoundingBoard" */ "../pages/DataDrivenAdvocacy/AboutDataDrivenAdvocacy"
    )
);

const FellowshipGuidelines = lazy(() =>
    import(
        /* webpackChunkName: "YouthSoundingBoardPage" */ "../pages/ClimateFellowship/Guidelines"
    )
);
const PublicationsPage = lazy(() =>
    import(
        /* webpackChunkName: "Publications" */ "../pages/Publications/PublicationsPage"
    )
);
const PublicationDetailsPage = lazy(() =>
    import(
        /* webpackChunkName: "Publications" */ "../pages/Publications/PublicationDetailsPage"
    )
);
const OurMentorPage = lazy(() => import("../pages/AboutPage/OurMentorPage"));
const OurpatMemberPage = lazy(() =>
    import("../pages/AboutPage/OurPatMemberPage")
);
const MentorDetailsPage = lazy(() =>
    import("../pages/AboutPage/MentorDetailsPage")
);
const PatmemberDetailPage = lazy(() =>
    import("../pages/AboutPage/PatmemberDetailsPage")
);
const MeetOurFellows = lazy(() =>
    import("../pages/ClimateFellowship/MeetOurFellows")
);
const FellowsDetailsPage = lazy(() =>
    import("../pages/ClimateFellowship/FellowDetailsPage")
);
const MemberListPage = lazy(() =>
    import("../pages/YouthSoundingBoard/MemberList")
);
const MemberDetailsPage = lazy(() =>
    import("../pages/YouthSoundingBoard/MembersDetailPage")
);
const CallForCandidate = lazy(() =>
    import("../pages/YouthSoundingBoard/CallForCandidate")
);

const MemberListPageDDA = lazy(() =>
    import("../pages/DataDrivenAdvocacy/MemberList")
);
const DigitalVolunteersPageDDA = lazy(() =>
    import("../pages/DataDrivenAdvocacy/MeetDigitalVolunteers")
);
const MemberDetailsPageDDA = lazy(() =>
    import("../pages/DataDrivenAdvocacy/MembersDetailPage")
);
const CallForCandidateDDA = lazy(() =>
    import("../pages/DataDrivenAdvocacy/CallForCandidate")
);

const WebRoute = () => (
    <Suspense fallback={<Loader />}>
        <Routes>
            <Route path="/" element={<HomePage />} exact />
            <Route
                path="/climate-smart-entrepreneurship"
                element={<LandingPageCSE />}
                exact
            />
            <Route
                path="/climate-smart-entrepreneurship/idea-repository"
                element={<IdeaRepository />}
                exact
            />
            <Route
                path="/climate-smart-entrepreneurship/idea-repository/:idea"
                element={<IdeaDetailPage />}
                exact
            />
            <Route
                path="/climate-smart-entrepreneurship/idea-submit"
                element={<IdeaSubmitPageCSE />}
                exact
            />
            <Route
                path="/climate-smart-entrepreneurship/about"
                element={<AboutCSE />}
                exact
            />
            <Route
                path="/climate-smart-entrepreneurship/blogs"
                element={<NewsPage />}
                exact
            />

            <Route
                path="/climate-smart-entrepreneurship/innovation-grant-winners"
                element={<InnovationGrantWinners />}
                exact
            />

            <Route
                path="/climate-smart-entrepreneurship/innovation-grant-winners/innovation-grant-winners-details"
                element={<InnovationGrantWinnersDetails />}
                exact
            />

            <Route
                path="/climate-smart-entrepreneurship/innovation-grant-winners/:idea"
                element={<InnovationGrantWinnersDetails />}
                exact
            />

            {/* <Route path="/grantdetails" element={<GrantWinnersDetails />} /> */}

            <Route
                path="/climate-fellowship"
                element={<ClimateFellowshipPage />}
                exact
            />
            <Route
                path="/climate-fellowship/about"
                element={<AboutClimateFellowship />}
                exact
            />
            <Route
                path="/climate-fellowship/blogs"
                element={<NewsPage />}
                exact
            />
            <Route
                path="/data-driven-advocacy"
                element={<LandingPageDDA />}
                exact
            />
            <Route
                path="/data-driven-advocacy/about"
                element={<AboutDataDrivenAdvocacy />}
                exact
            />
            <Route
                path="/data-driven-advocacy/blogs"
                element={<NewsPage />}
                exact
            />
            <Route
                path="/data-driven-advocacy/members"
                element={<MemberListPageDDA />}
                exact
            />
            <Route
                path="/data-driven-advocacy/members/:slug"
                element={<MemberDetailsPageDDA />}
                exact
            />
            <Route
                path="/data-driven-advocacy/call-for-candidates"
                element={<CallForCandidateDDA />}
                exact
            />
            <Route
                path="/data-driven-advocacy/meet-digital-volunteers"
                element={<DigitalVolunteersPageDDA />}
                exact
            />
            <Route
                path="/data-driven-advocacy/meet-digital-volunteers/:slug"
                element={<MembersDetailPage />}
                exact
            />

            <Route
                path="/youth-sounding-board"
                element={<LandingPageYSB />}
                exact
            />
            <Route
                path="/youth-sounding-board/about"
                element={<AboutYouthSoundingBoard />}
                exact
            />
            <Route
                path="/youth-sounding-board/blogs"
                element={<NewsPage />}
                exact
            />
            <Route
                path="/youth-sounding-board/members"
                element={<MemberListPage />}
                exact
            />
            <Route
                path="/youth-sounding-board/members/:slug"
                element={<MemberDetailsPage />}
                exact
            />
            <Route
                path="/youth-sounding-board/call-for-candidates"
                element={<CallForCandidate />}
                exact
            />
            <Route path="/about-us" element={<AboutUsPage />} exact />
            <Route path="/about-team" element={<OurTeamPage />} exact />
            <Route path="/about-team/:id" element={<TeamDetails />} exact />
            <Route path="/about-mentor" element={<OurMentorPage />} exact />
            <Route
                path="/about-mentor/:id"
                element={<MentorDetailsPage />}
                exact
            />
            <Route
                path="/about-pat-member"
                element={<OurpatMemberPage />}
                exact
            />
            <Route
                path="/about-pat-member/:id"
                element={<PatmemberDetailPage />}
                exact
            />
            <Route path="/events/:slug" element={<EventMainPage />} exact />
            <Route path="/events" element={<EventsPage />} exact />
            <Route path="/blogs/:slug" element={<NewsDetailsPage />} exact />
            <Route path="/blogs" element={<NewsPage />} exact />
            <Route
                path="/climate-fellowship/guidelines"
                element={<FellowshipGuidelines />}
                exact
            />
            <Route
                path="/climate-fellowship/fellows"
                element={<MeetOurFellows />}
                exact
            />
            <Route
                path="/climate-fellowship/fellows/:slug"
                element={<FellowsDetailsPage />}
                exact
            />
            <Route path="/publications" element={<PublicationsPage />} exact />
            <Route
                path="/publications/:slug"
                element={<PublicationDetailsPage />}
                exact
            />
            {/* Other Pages  */}
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    </Suspense>
);

export default WebRoute;
