import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
    years: []
};

export const getFellowDetails = createAsyncThunk(
    "fellowDetails/getFellowDetails",
    async (year, asyncThunk) => {
        const res = await fetch(`${baseURL}/fellow/?year=${year}`).then(
            (data) => data.json()
        );
        return res;
    }
);
export const getFellowYears = createAsyncThunk(
    "fellowDetails/getFellowYears",
    async (asyncThunk) => {
        const res = await fetch(
            `${baseURL}/fellowship-year/?ordering=-year`
        ).then((data) => data.json());
        return res;
    }
);
export const fellowDetailsSlice = createSlice({
    name: "fellowDetails",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFellowDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getFellowDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        });
        builder.addCase(getFellowDetails.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getFellowYears.fulfilled, (state, action) => {
            state.loading = false;
            state.years = action.payload.results;
        });
    }
});

export const fellowDetailsSelector = (state) => state.fellowDetails;

export default fellowDetailsSlice.reducer;
