import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getMunicipalities = createAsyncThunk(
    "municipalities/getMunicipalities",
    async (thunkAPI) => {
        const res = await fetch(`${baseURL}/municipality/?limit=-1`).then(
            (data) => data.json()
        );
        return res;
    }
);
export const municipalitiesSlice = createSlice({
    name: "municipalities",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMunicipalities.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getMunicipalities.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        });
        builder.addCase(getMunicipalities.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const municipalitiesSelector = (state) => state.municipalities;

export default municipalitiesSlice.reducer;
