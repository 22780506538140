import React, { useEffect } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
const LoaderComponent = ({ status = false }) => {
    useEffect(() => {
        if (status) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [status]);

    return (
        <div className="fixed h-screen top-0 left-0 bg-white w-full z-50 opacity-100">
            <div
                className="fixed top-2/4 left-2/4"
                style={{ transform: "translate(-50%,-50%)" }}
            >
                <PropagateLoader color="#BC014E" />
            </div>
        </div>
    );
};
export default LoaderComponent;
