import React, { useEffect } from "react";
import Container from "../../components/containers/Container";
import Animate from "../../components/Animate";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HtmlReactParser from "html-react-parser";
import Loader from "../../components/loader/Loader";
import ImageRe from "../../components/image";
import { getDigitalVolunteerIndividualDetails } from "../../features/DigitalVolunteersDetails/VolunteersIndividualSlice";
import { FaUser } from "react-icons/fa";
import PageHeadingDDA from "../../components/heading/PageHeadingDDA";
import ysbMembers from "../../assets/images/ysb-members.jpg";
const MembersDetailPage = () => {
    const { slug } = useParams();

    const { data: DDAIndividualVolunteerData, loading } = useSelector(
        (state) => state.digitalVolunteerIndividualDetails
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDigitalVolunteerIndividualDetails(slug));
    }, []);
    const {
        email,
        facebook_link,
        twitter_link,
        description,
        designation,
        name,
        picture
    } = DDAIndividualVolunteerData;

    return (
        <>
            <PageHeadingDDA heading="Digital Volunteers">
                {/* <div
                    className="h-full w-full"
                    style={{
                        background: `url(${ysbMembers})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%"
                    }}
                ></div> */}
            </PageHeadingDDA>

            {loading ? (
                <Loader />
            ) : (
                <>
                    <Animate animateIn="fadeIn">
                        <section className="bg-gray-200">
                            <Container className="py-5">
                                <div className="ml-4 mr-4 flex opacity-60 text-sm">
                                    <Link
                                        to="/data-driven-advocacy"
                                        className="hover:cursor-pointer hover:underline"
                                    >
                                        {" "}
                                        <p>Data Driven Advocacy</p>
                                    </Link>
                                    &nbsp;{">"}&nbsp;
                                    <Link
                                        to="/data-driven-advocacy/meet-digital-volunteers"
                                        className="hover:cursor-pointer hover:underline"
                                    >
                                        <p>Digital Volunteers</p>
                                    </Link>
                                    &nbsp;{">"}&nbsp;
                                    <p>{name}</p>
                                </div>

                                <div className="ml-4 mr-4 py-10 max-w-7xl flex-col lg:flex lg:flex-row">
                                    <div className="mr-0 mb-10 lg:mr-10">
                                        <div className="max-w-full w-96 h-96 object-contain lg:max-w-none">
                                            {picture ? (
                                                <ImageRe
                                                    src={picture}
                                                    className="object-cover"
                                                    alt="fellow"
                                                />
                                            ) : (
                                                <FaUser className="h-96 w-96" />
                                            )}
                                        </div>
                                        {email ||
                                        facebook_link ||
                                        twitter_link ? (
                                            <div className="flex items-center justify-end mt-2">
                                                <h2 className="ml-1 mr-2 opacity-60 font-wwf text-lg">
                                                    GET IN TOUCH
                                                </h2>
                                                {facebook_link ? (
                                                    <a
                                                        href={`${facebook_link}`}
                                                        target="_blank"
                                                    >
                                                        <div className="ml-1 mr-1 cursor-pointer text-xs bg-[#f15a29] text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-orange-400">
                                                            <i className="fa-brands fa-facebook-f"></i>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                                {twitter_link ? (
                                                    <a
                                                        href={`${twitter_link}`}
                                                        target="_blank"
                                                    >
                                                        <div className="ml-1 mr-1 cursor-pointer text-xs bg-[#f15a29] text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-orange-400">
                                                            <i className="fa-brands fa-twitter"></i>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                                {email ? (
                                                    <a
                                                        href={`mailto:${email}`}
                                                        target="_blank"
                                                    >
                                                        <div className="ml-1 mr-1 cursor-pointer text-xs bg-[#f15a29] text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-orange-400">
                                                            <i className="fa-solid fa-envelope"></i>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div>
                                        <h1>{name}</h1>
                                        <h4 className="mb-5 opacity-60">
                                            {designation}
                                        </h4>
                                        {description ? (
                                            <div className="font-graphik_regular opacity-90">
                                                {" "}
                                                {HtmlReactParser(description)}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </Container>
                        </section>
                    </Animate>
                </>
            )}
        </>
    );
};

export default MembersDetailPage;
