import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const Animate = ({ children, animateIn, duration = 0.5 }) => {
    const fadeIn = {
        visible: {
            opacity: 1,
            translateY: 0,
            transition: { duration: duration }
        },
        hidden: { opacity: 0, translateY: 30 }
    };

    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            className="lazy-div w-full"
            initial="hidden"
            animate={controls}
            variants={fadeIn}
        >
            {children}
        </motion.div>
    );
};

export default Animate;
