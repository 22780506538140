import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Animate from "../Animate";
import Container from "../containers/Container";
import { getProjectGuideline } from "../../features/projectGuidelines/projectGuidelinesSlice";
import { getProjectHighlights } from "../../features/projectHighlights/projectHighlightsSlice";
import { useDispatch, useSelector } from "react-redux";
/**
 *
 * @param {children} Component  !! only pass gradient with absolute div and Background Image in this parameter
 * @param {heading} String
 * @param {description} String
 * @param {link} String
 *
 * @returns
 */
const PageHeading = (props) => {
    const { children, heading, description, link, download } = props;
    const [pathName, setPathName] = useState("");
    const { pathname } = useLocation();

    useEffect(() => {
        const path = pathname.substring(1);
        setPathName(path);
    }, [pathname]);

    const [language, setLanguage] = useState();
    const dispatch = useDispatch();
    const guideline = useSelector((state) => state.projectGuideline.data);
    useEffect(() => {
        dispatch(getProjectGuideline("Climate Smart Entrepreneurship"));
    }, [dispatch]);

    useEffect(() => {
        const temp = guideline.map((item) => item.language);
        setLanguage([...new Set(temp)]);
    }, [guideline]);

    return (
        <section className="print:hidden">
            <div className="w-full page-heading relative">
                {!!children ? (
                    children
                ) : (
                    <div className="overlay absolute top-0 right-0 bottom-0 left-0 opacity-60 bg-gradient-to-t from-darkBlack via-reddishBrown via-red-700 to-black"></div>
                )}

                <Container className="absolute bottom-0 left-0 right-0 pb-20 flex flex-col justify-end  px-4 lg:px-0">
                    {!!heading && (
                        <Animate animateIn={"fadeIn"} duration={0.6}>
                            <h2 className="text-white text-5xl font-wwf max-w-xl drop-shadow-lg shadow-black banner-title">
                                {heading}
                            </h2>
                        </Animate>
                    )}

                    {!!description && (
                        <Animate animateIn={"fadeIn"} duration={0.8}>
                            <p className="max-w-3xl mt-3 font-light font-graphik_regular text-white opacity-90 text-base">
                                {description}
                            </p>
                        </Animate>
                    )}

                    {!!link && (
                        <Animate animateIn={"fadeIn"} duration={1}>
                            <div className="flex">
                                <Link
                                    to={link}
                                    className="group transition-all text-white bg-red-500 flex px-5 py-3 mt-5 rounded-md items-center hover:bg-red-600"
                                >
                                    <span className="mr-3 transition-all group-hover:mr-4 font-sans text-sm">
                                        LEARN MORE
                                    </span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14 5l7 7m0 0l-7 7m7-7H3"
                                        />
                                    </svg>
                                </Link>
                            </div>
                        </Animate>
                    )}
                    {!!download && (
                        <Animate animateIn={"fadeIn"} duration={1}>
                            <div className="group flex flex-col max-w-xs py-5 cursor-pointer">
                                {language && language.length > 1 ? (
                                    <div className="w-full mt-3 flex bg-red-500 items-center py-4 text-[#F4EFE5] rounded-full px-7 text-base font-semibold group-hover:bg-red-500 group-hover:text-white group-hover:px-8 max-w-fit transition-all">
                                        <div className="flex justify-center">
                                            <div>
                                                <div className="dropdown relative min-w-[200]">
                                                    <button
                                                        className="dropdown-toggle rounded flex items-center"
                                                        type="button"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        Download Guideline
                                                        <div className="pl-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="group-hover:animate-bounce h-5 w-5"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    <ul
                                                        className="dropdown-menu min-w-[150px] absolute bg-white text-base z-50 float-left py-2 list-none text-left shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                                                        aria-labelledby="dropdownMenuButton1"
                                                    >
                                                        {language.map(
                                                            (item) => (
                                                                <li key={item}>
                                                                    <a
                                                                        className="uppercase dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                                                                        href={
                                                                            guideline.filter(
                                                                                (
                                                                                    lang
                                                                                ) =>
                                                                                    lang.language ===
                                                                                    item
                                                                            )[0]
                                                                                ?.file
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {item}
                                                                    </a>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <a
                                        href={guideline[0]?.file}
                                        target="_blank"
                                        className="w-full mt-3 flex items-center py-4 text-[#F4EFE5] text-base font-semibold bg-red-500 px-3 rounded-full group-hover:text-white group-hover:px-4 max-w-fit transition-all"
                                        rel="noreferrer"
                                    >
                                        <span className="mr-2">
                                            Download Guideline
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="group-hover:animate-bounce h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                            />
                                        </svg>
                                    </a>
                                )}
                            </div>
                        </Animate>
                    )}
                </Container>
            </div>
        </section>
    );
};
export default PageHeading;
