import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
    filter: {
        province: undefined,
        sdg: undefined,
        minFund: undefined,
        maxFund: undefined,
        isWinner: undefined
    }
};

export const getIdea = createAsyncThunk(
    "idea/getIdea",
    async (_, { getState }) => {
        const state = getState();
        const res = await fetch(
            `${baseURL}/idea?is_verified=true&province=${
                state.idea.filter.province === undefined
                    ? ""
                    : state.idea.filter.province
            }&fund_seeking_min=${
                state.idea.filter.minFund === undefined
                    ? ""
                    : state.idea.filter.minFund
            }&fund_seeking_max=${
                state.idea.filter.maxFund === undefined
                    ? ""
                    : state.idea.filter.maxFund
            }&expand=sdg_goal,sdg_target&sdg_goal__short_title=${
                state.idea.filter.sdg ?? ""
            }&is_innovation_grant_winner=${state.idea.filter.isWinner ?? ""}`
        ).then((data) => data.json());
        return res;
    }
);

export const ideaSlice = createSlice({
    name: "idea",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setFilter: (state, action) => {
            state.filter.province = action.payload?.province;
            state.filter.sdg = action.payload?.sdgValue;
            state.filter.minFund = action.payload?.fundSeeking
                ? action.payload?.fundSeeking[0]
                : undefined;
            state.filter.maxFund = action.payload?.fundSeeking
                ? action.payload?.fundSeeking[1]
                : undefined;
            state.filter.isWinner = action.payload?.isWinner;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getIdea.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getIdea.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getIdea.rejected, (state) => {
            state.loading = false;
        });
    }
});
export const { setFilter } = ideaSlice.actions;

export const ideaSelector = (state) => state.idea;

export default ideaSlice.reducer;
