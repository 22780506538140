import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
    loadingOne: false,
    dataOne: {},
    loadingTypes: false,
    pubTypes: []
};

export const getPublications = createAsyncThunk(
    "publications/getPublications",
    async (params, thunkApi) => {
        const { offset = 0, limit = 25, pubType = "" } = params;
        const res = await fetch(
            `${baseURL}/publications?offset=${offset}&limit=${limit}&publication_type=${pubType}&ordering=-published_date`
        ).then((data) => data.json());
        return res;
    }
);
export const getPublicationOne = createAsyncThunk(
    "publications/getPublicationOne",
    async (slug) => {
        const res = await fetch(`${baseURL}/publications?slug=${slug}`).then(
            (data) => data.json()
        );
        // console.log(
        //     "test pub",
        //     res.results.find((pub) => pub.slug === slug)
        // );
        return res.results?.length > 0
            ? res.results.find((pub) => pub.slug === slug)
            : {};
    }
);
export const getPublicationTypes = createAsyncThunk(
    "publications/getPublicationTypes",
    async () => {
        const res = await fetch(`${baseURL}/publication-type?ordering=id`).then(
            (data) => data.json()
        );
        return res;
    }
);
export const publicationsSlice = createSlice({
    name: "publications",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        //case getPublications , optional params- limit
        builder.addCase(getPublications.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPublications.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        });
        builder.addCase(getPublications.rejected, (state) => {
            state.loading = false;
        });

        //case getPublicationOne
        builder.addCase(getPublicationOne.pending, (state) => {
            state.loadingOne = true;
        });
        builder.addCase(getPublicationOne.fulfilled, (state, action) => {
            state.loadingOne = false;
            state.dataOne = action.payload;
        });
        builder.addCase(getPublicationOne.rejected, (state) => {
            state.loadingOne = false;
        });

        //case getPublicationTypes
        builder.addCase(getPublicationTypes.pending, (state) => {
            state.loadingTypes = true;
        });
        builder.addCase(getPublicationTypes.fulfilled, (state, action) => {
            state.loadingTypes = false;
            state.pubTypes = action.payload.results;
        });
        builder.addCase(getPublicationTypes.rejected, (state) => {
            state.loadingTypes = false;
        });
    }
});

export const publicationsSelector = (state) => state.publicationsSlice;

export default publicationsSlice.reducer;
