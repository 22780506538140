import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: true,
    data: [],
    years: []
};

export const getDigitalVolunteerDetails = createAsyncThunk(
    "digitalVolunteerDetails/getDigitalVolunteerDetails",
    async (year, asyncThunk) => {
        const res = await fetch(
            `${baseURL}/digital-volunteer/?year=${year}`
        ).then((data) => data.json());
        return res;
    }
);

export const getDigitalVolunteerYears = createAsyncThunk(
    "digitalVolunteerDetails/getDigitalVolunteerYears",
    async (asyncThunk) => {
        const res = await fetch(
            `${baseURL}/digital-volunteer-year/?ordering=-year`
        ).then((data) => data.json());
        return res;
    }
);

export const digitalVolunteerDetailsSlice = createSlice({
    name: "digitalVolunteerDetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDigitalVolunteerDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getDigitalVolunteerDetails.fulfilled,
            (state, action) => {
                state.loading = false;
                state.data = action.payload.results;
            }
        );
        builder.addCase(getDigitalVolunteerDetails.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getDigitalVolunteerYears.fulfilled, (state, action) => {
            state.loading = false;
            state.years = action.payload.results;
        });
    }
});

export const digitalVolunteerDetailsSelector = (state) =>
    state.digitalVolunteerDetails;

export default digitalVolunteerDetailsSlice.reducer;
