import React, { useEffect, useRef, useState } from "react";

const ImageRe = (props) => {
    const {
        src,
        height = null,
        width = null,
        className = null,
        ...attribs
    } = props;

    const [source, setSource] = useState(src);
    const imgRef = useRef();

    useEffect(() => {
        if (height && width) {
            setSource(`${src}?height=${height}&width=${width}`);
        }
        // else {
        //     const ch = imgRef.current.clientHeight;
        //     const cw = imgRef.current.clientWidth;
        //     setSource(`${src}?height=${ch}&width=${cw}`);
        // }
    }, []);

    return (
        <img
            ref={imgRef}
            src={source}
            style={{ width: "100%", height: "100%" }}
            className={className ? className : ""}
            loading="lazy"
            {...attribs}
        />
    );
};

export default ImageRe;
