import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getWards = createAsyncThunk(
    "wards/getWards",
    async (params, thunkAPI) => {
        const res = await fetch(
            `${baseURL}/ward/?limit=-1&municipality=${params}`
        ).then((data) => data.json());
        return res;
    }
);
export const wardsSlice = createSlice({
    name: "wards",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getWards.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getWards.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        });
        builder.addCase(getWards.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const wardsSelector = (state) => state.wards;

export default wardsSlice.reducer;
