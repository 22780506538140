import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
    years: []
};

export const getYSBMemberDetails = createAsyncThunk(
    "YSBMemberDetails/getYSBMemberDetails",
    async (year, thunkAPI) => {
        const res = await fetch(`${baseURL}/ysb-fellow/?year=${year}`).then(
            (data) => data.json()
        );
        return res;
    }
);
export const getYSBMemberYears = createAsyncThunk(
    "YSBMemberDetails/getYSBMemberYears",
    async (thunkAPI) => {
        const res = await fetch(`${baseURL}/ysb/?ordering=-year`).then((data) =>
            data.json()
        );
        return res;
    }
);
export const memberDetailsSlice = createSlice({
    name: "YSBMemberDetails",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getYSBMemberDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getYSBMemberDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        });
        builder.addCase(getYSBMemberDetails.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(getYSBMemberYears.fulfilled, (state, action) => {
            state.years = action.payload.results;
        });
    }
});

export const memberDetailsSelector = (state) => state.memberDetails;

export default memberDetailsSlice.reducer;
