import React, { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import WebRoute from "./components/WebRoute";
import "./App.css";
import Store from "./utils/Store";

function App() {
    const map = useRef(null);
    return (
        <Store>
            <BrowserRouter>
                <Header />
                <main className="min-h-[80vh] relative w-full">
                    <WebRoute />
                </main>
                <Footer />
            </BrowserRouter>
        </Store>
    );
}

export default App;
