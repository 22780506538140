import { configureStore } from "@reduxjs/toolkit";
import provincesReducer from "../features/provinces/provincesSlice";
import districtsReducer from "../features/districts/districtsSlice";
import municipalitiesReducer from "../features/municipalities/municipalitiesSlice";
import wardsReducer from "../features/wards/wardsSlice";
import guidelineReducer from "../features/guideline/guidelineSlice";
import ourReachReducer from "../features/ourReach/ourReachSlice";
import slidersReducer from "../features/sliders/slidersSlice";
import faqReducer from "../features/faq/faqSlice";
import projectHighlightsReducer from "../features/projectHighlights/projectHighlightsSlice";
import projectsReducer from "../features/projects/projectsSlice";
import aboutUsReducer from "../features/aboutUs/aboutUsSlice";
import eventsReducer from "../features/events/eventsSlice";
import teamCategoryReducer from "../features/teamCategory/teamCategorySlice";
import teamSliceReducer from "../features/team/team";
import teamMemberReducer from "../features/teamMembers/teamMemberSlice";
import sdgReducer from "../features/sdg/sdgSlice";
import ideaReducer from "../features/idea/IdeaSlice";
import projectGuidelinesSlice from "../features/projectGuidelines/projectGuidelinesSlice";
import MentorSliceReducer from "../features/Mentors/MentorSlice";
import MentorDetailsReducer from "../features/Mentors/MentorDetails";
import FellowsDetailsSlice from "../features/FellowsDetails/FellowsDetailsSlice";
import FellowsIndividualSlice from "../features/FellowsDetails/FellowsIndividualSlice";
import YSBMemberDetailsSlice from "../features/ysbMembersDetail/MembersDetailSlice";
import YSBIndividualSlice from "../features/ysbMembersDetail/MemberIndividualSlice";
import publicationsSlice from "../features/publications/publicationsSlice";
import digitalVolunteersDetailsSlice from "../features/DigitalVolunteersDetails/VolunteersDetailsSlice";
import digitalVolunteerIndividualDetailsSlice from "../features/DigitalVolunteersDetails/VolunteersIndividualSlice";

export const store = configureStore({
    reducer: {
        provinces: provincesReducer,
        districts: districtsReducer,
        municipalities: municipalitiesReducer,
        wards: wardsReducer,
        guideline: guidelineReducer,
        ourReach: ourReachReducer,
        sliders: slidersReducer,
        faq: faqReducer,
        projectHighlights: projectHighlightsReducer,
        projects: projectsReducer,
        aboutUs: aboutUsReducer,
        events: eventsReducer,
        teamCategory: teamCategoryReducer,
        teamMember: teamMemberReducer,
        team: teamSliceReducer,
        idea: ideaReducer,
        sdg: sdgReducer,
        projectGuideline: projectGuidelinesSlice,
        mentor: MentorSliceReducer,
        mentorDetails: MentorDetailsReducer,
        fellowDetails: FellowsDetailsSlice,
        fellowIndividualDetails: FellowsIndividualSlice,
        ysbMemberDetails: YSBMemberDetailsSlice,
        ysbMemberIndividualDetails: YSBIndividualSlice,
        publications: publicationsSlice,
        digitalVolunteersDetails: digitalVolunteersDetailsSlice,
        digitalVolunteerIndividualDetails:
            digitalVolunteerIndividualDetailsSlice
    },
    devTools: false
});
