import { createContext, useState } from "react";

export const filteredProvince_Context = createContext("All");

const Store = ({ children }) => {
    const [filteredProvince, setFilteredProvince] = useState("All");
    return (
        <filteredProvince_Context.Provider
            value={[filteredProvince, setFilteredProvince]}
        >
            {children}
        </filteredProvince_Context.Provider>
    );
};

export default Store;
