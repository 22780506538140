import "../assets/css/app.css";

import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getProvinces } from "../features/provinces/provincesSlice";
import { getDistricts } from "../features/districts/districtsSlice";
import { getMunicipalities } from "../features/municipalities/municipalitiesSlice";
// import { navs } from "../constants/navs";
import Container from "./containers/Container";
import logo from "../../src/assets/images/main-logo.svg";
import { getWards } from "../features/wards/wardsSlice";

export function InfoBox(props) {
    const ref = useRef(null);
    const { onClickOutside, mainDivClicked } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        if (!mainDivClicked) {
            document.addEventListener("click", handleClickOutside, true);
            return () => {
                document.removeEventListener("click", handleClickOutside, true);
            };
        } else {
            document.addEventListener("click", handleClickOutside, false);
            return () => {
                document.removeEventListener(
                    "click",
                    handleClickOutside,
                    false
                );
            };
        }
    }, [onClickOutside]);

    if (!props.show) return null;

    return (
        <div ref={ref} className="info-box" style={{ display: "none" }}></div>
    );
}

const Header = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [clickedAbout, setClickedAbout] = useState(false);
    const [clickedBlog, setClickedBlog] = useState(false);
    // const [clickedMenu, setClickedMenu] = useState(false); // for menus of the youth can titles
    const [clickedMainAboutDiv, setClickedMainAboutDiv] = useState(false);
    const nav = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const slug = location.pathname.split("/")[1];
    const subSlug = location.pathname.split("/")[2];
    const [activeColor, setActiveColor] = useState("red");

    const sidebarToggleHandler = () => {
        setOpenSidebar(!openSidebar);
        if (!openSidebar) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "scroll";
        }
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    // const sidebarToggleHandler = () => {
    //     const screenWidth = window.innerWidth;
    //     if (screenWidth <= 1536) {
    //         setOpenSidebar(!openSidebar);
    //         document.body.style.overflow = openSidebar ? "scroll" : "hidden";
    //     } else {
    //         setOpenSidebar(false);
    //         document.body.style.overflow = "scroll";
    //     }
    // };

    const getActivePage = () => {
        if (slug === "climate-smart-entrepreneurship") {
            return subSlug === "about" ? 5 : 1;
        } else if (slug === "climate-fellowship") {
            return subSlug === "about" ? 5 : 2;
        } else if (slug === "youth-sounding-board") {
            return subSlug === "about" ? 5 : 3;
        } else if (slug === "blogs" || slug === "publications") {
            return 4;
        } else if (slug === "about-us" || slug == "about-team") {
            return 5;
        }
        return 0;
    };
    const colors = ["red", "red", "green", "blue", "red", "red"];

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "instant"
        });
        setOpenSidebar(false);
        setActiveColor(colors[getActivePage()]);
    }, [nav]);

    // Accessing redux values
    const { data } = useSelector((state) => state.provinces);

    // Call the api as this where needed
    useEffect(() => {
        dispatch(getProvinces());
        dispatch(getDistricts());
        dispatch(getMunicipalities());
        dispatch(getWards(""));
    }, [dispatch]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "instant"
        });
        setOpenSidebar(false);
        setActiveColor(colors[getActivePage()]);
    }, [nav]);

    return (
        <header className="bg-black h-20 print:hidden">
            <Container className=" h-full flex items-center justify-between relative">
                <Link
                    to="/"
                    className=" h-full flex items-center justify-center lg:-ml-3"
                >
                    <span
                        className={`h-full w-[70px] object-cover ${
                            slug === "climate-fellowship"
                                ? "bg-green-500"
                                : slug === "youth-sounding-board"
                                ? "bg-blue-500"
                                : slug === "data-driven-advocacy"
                                ? `bg-[#F15A29]`
                                : "bg-red-500"
                            // : `bg-${activeColor}-500`
                        }`}
                    >
                        <img
                            src={logo}
                            alt=""
                            className="h-full object-cover"
                        />
                    </span>
                    <span className="pl-1 text-white font-wwf text-3xl">
                        TheYouthCAN
                    </span>
                </Link>
                <nav
                    className={
                        "primary-menu z-[100] overflow-visible " +
                        (openSidebar ? "expanded" : "")
                    }
                >
                    {slug === "youth-sounding-board" ? (
                        <button
                            className="block 2xl:hidden text-white bg-blue-400 rounded-full p-2 fixed top-4 right-4 fill-zinc-500"
                            onClick={sidebarToggleHandler}
                        >
                            {openSidebar ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-7 w-7"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    ) : slug === "climate-fellowship" ? (
                        <button
                            className="block 2xl:hidden text-white mr-4 bg-green-400 rounded-full p-2 fixed top-4 right-4 fill-zinc-500"
                            onClick={sidebarToggleHandler}
                        >
                            {openSidebar ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-7 w-7"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    ) : slug === "data-driven-advocacy" ? (
                        <button
                            className="block 2xl:hidden text-white mr-4 bg-orange-600 rounded-full p-2 fixed top-4 right-4 fill-zinc-500"
                            onClick={sidebarToggleHandler}
                        >
                            {openSidebar ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-7 w-7"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    ) : (
                        <button
                            className="block 2xl:hidden text-white mr-4 bg-red-400 rounded-full p-2 fixed top-4 right-4 fill-zinc-500"
                            onClick={sidebarToggleHandler}
                        >
                            {openSidebar ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-7 w-7"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    )}

                    <ul className="text-white nav-list overflow-visible flex whitespace-nowrap">
                        {/* <div className="text-white nav-list overflow-visible flex whitespace-nowrap"> */}
                        <li
                            className={
                                slug === "climate-smart-entrepreneurship" &&
                                !subSlug
                                    ? "active_header_element active_menu"
                                    : ""
                            }
                            onClick={() => handleTabClick(0)}
                        >
                            <Link to="/climate-smart-entrepreneurship">
                                CLIMATE SMART ENTREPRENEURSHIP
                            </Link>
                        </li>
                        <li
                            className={
                                slug === "climate-fellowship" && !subSlug
                                    ? "active_header_element active_menu before:border-green-500 after:border-t-green-500"
                                    : ""
                            }
                            onClick={() => handleTabClick(0)}
                        >
                            <Link
                                to="/climate-fellowship"
                                onClick={() =>
                                    (document.body.style.overflow = "scroll")
                                }
                            >
                                CLIMATE FELLOWSHIP
                            </Link>
                        </li>
                        <li
                            className={
                                slug === "youth-sounding-board" && !subSlug
                                    ? "active_header_element active_menu before:border-blue-500 after:border-t-blue-500"
                                    : ""
                            }
                        >
                            <Link
                                to="/youth-sounding-board"
                                onClick={() =>
                                    (document.body.style.overflow = "scroll")
                                }
                            >
                                YOUTH SOUNDING BOARD
                            </Link>
                        </li>
                        <li
                            className={
                                slug === "data-driven-advocacy" && !subSlug
                                    ? "active_header_element  active_menu before:border-[#f57e20] after:border-t-[#f57e20]"
                                    : ""
                            }
                        >
                            <Link
                                to="/data-driven-advocacy"
                                onClick={() =>
                                    (document.body.style.overflow = "scroll")
                                }
                            >
                                DATA DRIVEN ADVOCACY
                            </Link>
                        </li>
                        {/* </div> */}
                        {/* <li
                            className={
                                slug === "blogs" && !subSlug
                                    ? "active_header_element before:border-red-500 after:border-t-red-500"
                                    : ""
                            }
                        >
                            <Link
                                to="/blogs"
                                onClick={() =>
                                    (document.body.style.overflow = "scroll")
                                }
                            >
                                BLOG
                            </Link>
                        </li> */}
                        {/* dropdown menu for the youthcan titles starts here */}
                        {/* <li
                            className={`group relative title overflow-visible ${
                                slug === "climate-smart-entrepreneurship" ||
                                slug === "climate-fellowship" ||
                                slug === "youth-sounding-board" ||
                                slug === "data-driven-advocacy"
                                    ? "relative"
                                    : ""
                            }`}
                        >
                            <Link
                                to="#"
                                onClick={() => {
                                    setClickedMainAboutDiv(
                                        !clickedMainAboutDiv
                                    );
                                    setClickedMenu(!clickedMenu);
                                    document.body.style.overflow = "scroll";
                                }}
                            >
                                <InfoBox
                                    show={clickedMenu}
                                    onClickOutside={() => {
                                        setClickedMenu(false);
                                        setClickedMainAboutDiv(false);
                                    }}
                                    mainDivClicked={clickedMainAboutDiv}
                                />
                                <span className="mr-1">TITLE</span>
                                <span>
                                    <IoMdArrowDropdown />
                                </span>
                            </Link>
                            {clickedMenu ? (
                                <ul className="primary-sub-menu max-w-[200px]">
                                    <li className="">
                                        <Link
                                            to="/climate-smart-entrepreneurship"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Climate Smart Entrepreneurship
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/climate-fellowship"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Climate Fellowship
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/youth-sounding-board"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Youth Sounding Board
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/data-driven-advocacy"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Data Driven Advocacy
                                        </Link>
                                    </li>
                                </ul>
                            ) : null}

                             </li>

                            {/* Dropdown menu for youthcan titles end here */}

                        <li
                            className={`group relative overflow-visible ${
                                slug === "blogs" || slug === "publications"
                                    ? "active_header_element relative"
                                    : ""
                            }`}
                        >
                            <Link
                                to="#"
                                onClick={() => {
                                    setClickedMainAboutDiv(
                                        !clickedMainAboutDiv
                                    );
                                    setClickedBlog(!clickedBlog);
                                    document.body.style.overflow = "scroll";
                                }}
                            >
                                <InfoBox
                                    show={clickedBlog}
                                    onClickOutside={() => {
                                        setClickedBlog(false);
                                        setClickedMainAboutDiv(false);
                                    }}
                                    mainDivClicked={clickedMainAboutDiv}
                                />
                                <span className="mr-1">BLOG</span>
                                <span>
                                    <IoMdArrowDropdown />
                                </span>
                            </Link>
                            {clickedBlog ? (
                                <ul className="primary-sub-menu max-w-[200px]">
                                    <li className="">
                                        <Link
                                            to="/blogs"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Blog & News
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/publications"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Publications
                                        </Link>
                                    </li>
                                </ul>
                            ) : null}
                        </li>
                        <li
                            className={`group relative overflow-visible ${
                                slug === "climate-fellowship" &&
                                subSlug === "about"
                                    ? "active_header_element before:border-green-500 after:border-t-green-500"
                                    : slug === "about-us" ||
                                      slug === "about-team" ||
                                      (slug ===
                                          "climate-smart-entrepreneurship" &&
                                          subSlug === "about")
                                    ? "active_header_element before:border-red-500 after:border-t-red-500"
                                    : slug === "youth-sounding-board" &&
                                      subSlug === "about"
                                    ? "active_header_element before:border-blue-500 after:border-t-blue-500"
                                    : slug === "data-driven-advocacy" &&
                                      subSlug === "about"
                                    ? "active_header_element before:border-[#f57e20] after:border-t-[#f57e20]"
                                    : ""
                            }`}
                            //   subSlug === "about"
                            // ? "active_header_element"
                            // : ""
                        >
                            <Link
                                to="#"
                                onClick={() => {
                                    setClickedMainAboutDiv(
                                        !clickedMainAboutDiv
                                    );
                                    setClickedAbout(!clickedAbout);
                                    document.body.style.overflow = "scroll";
                                }}
                            >
                                <InfoBox
                                    show={clickedAbout}
                                    onClickOutside={() => {
                                        setClickedAbout(false);
                                        setClickedMainAboutDiv(false);
                                    }}
                                    mainDivClicked={clickedMainAboutDiv}
                                />
                                <span className="mr-1">ABOUT</span>
                                <span>
                                    <IoMdArrowDropdown />
                                </span>
                            </Link>
                            {clickedAbout ? (
                                <ul className="primary-sub-menu max-w-[200px]">
                                    <li className="">
                                        <Link
                                            to="/about-us"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            TheYouthCAN.org
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/climate-smart-entrepreneurship/about"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Climate Smart​ Entrepreneurship​
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/climate-fellowship/about"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Climate Fellowship
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/youth-sounding-board/about"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Youth Sounding Board
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/data-driven-advocacy/about"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Data Driven Advocacy
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/about-team"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Our Team
                                        </Link>
                                    </li>
                                </ul>
                            ) : (
                                ""
                            )}
                        </li>

                        {/* <li>
                            {slug === "youth-sounding-board" ? (
                                <Link
                                    to="/climate-smart-entrepreneurship/idea-submit"
                                    className="relative bg-blue-500 px-5 py-2 rounded-md font-sans text-sm btn transition-all hover:bg-blue-600 active:bg-blue-700 text-white"
                                >
                                    SUBMIT IDEA
                                </Link>
                            ) : slug === "climate-fellowship" ? (
                                <Link
                                    to="/climate-smart-entrepreneurship/idea-submit"
                                    className="relative bg-green-500 px-5 py-2 rounded-md font-sans text-sm btn transition-all hover:bg-green-600 active:bg-green-700 text-white"
                                >
                                    SUBMIT IDEA
                                </Link>
                            ) : slug === "data-driven-advocacy" ? (
                                <Link
                                    to="/data-driven-advocacy/idea-submit"
                                    className="relative bg-orange-600 px-5 py-2 rounded-md font-sans text-sm btn transition-all hover:bg-[#f2737c] bg-[#f2737c]-600 active:bg-[#f2737c] bg-[#f2737c]-700 text-white"
                                >
                                    SUBMIT IDEA
                                </Link>
                            ) : (
                                <Link
                                    to="/climate-smart-entrepreneurship/idea-submit"
                                    className="relative bg-red-500 px-5 py-2 rounded-md font-sans text-sm btn transition-all hover:bg-red-600 active:bg-red-700 text-white"
                                >
                                    SUBMIT IDEA
                                </Link>
                            )}
                        </li> */}
                    </ul>
                </nav>
            </Container>
        </header>
    );
};

export default Header;
